<template>
	<section>
		<b-row style="justify-content: space-between" class="mb-2 align-center">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>
		    <b-col>
		    	<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
			      	<b-form-group class="mb-0">
				        <b-input-group class="input-group-merge">
					        <b-input-group-prepend is-text>
					            <feather-icon icon="SearchIcon" size="17" />
					        </b-input-group-prepend>
					        <b-form-input
					            placeholder="Search"
					            @input="searchValue"
					        />
				        </b-input-group>
			      	</b-form-group>
			    </div>
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">
		    	<div>
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Product Listing" active class="overview-tab products-table">
					        <b-card class="px-table-0 w-10-percent-cl-1 w-20-percent-cl-2 w-25-percent-cl-3 w-30-percent-cl-4">
					        	<b-row class="align-center">
						            <b-col lg="6" style="padding-left: 33px ">
						                <h2 class="mb-2"><strong>Products</strong></h2>
						            </b-col>
						            <b-col lg-6>
						            	<div class="text-right mb-2 pr-2">
						            		<template v-if="(userData && permission.create)">

							                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="addProduct">
							                        Add Product
							                    </b-button>
							                </template>
							                <template v-else>
							                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
							                        Add Product
							                    </b-button>
							                </template>
						                </div>
						            </b-col>
						        </b-row>
							    <b-table small :fields="fields" :items="itemsData" tbody-tr-class="size-14 table-row cursor-pointer" show-empty @head-clicked="headClicked" @row-clicked="single_product" id="custom-table">
							    	<template #head(id)="data">
							    		<span>{{ data.label }}</span>
							    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 30px;">
								        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
								        </span>
							    	</template>
							    	<template #head()="data">
							    		<span class="d-block text-center">{{ data.label }}</span>
							    	</template>	
							    	<template #head(name)="data">
							    		<span>{{ data.label }}</span>
							    	</template>							    	
							    	<template #cell(id)="data">
										<span>{{ data.item.id }}</span>
									</template>	
									<template #cell(name)="data">
										<span class="maxTwoRow">{{ data.item.name }}</span>
									</template>	
									<template #cell(service)="data">
										<span class="maxTwoRow text-center">{{ data.item.service }}</span>
									</template>	
									<template #cell(description)="data">
										<span class="maxThreeRow text-center mx-auto w-400">{{ data.item.description }}</span>
									</template>			
									<template #cell(action)="data">
										<div class="d-block text-center">
											<template v-if="(userData && permission.delete)">
												<span class="px-05 cursor-pointer" @click.stop="showDeleteAction(data.item.id, data.item.name)">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
													</svg>
												</span>
											</template>
											<template v-else>
												<span class="px-05">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
													</svg>
												</span>
											</template>
										</div>										
									</template>		
									<template #cell()="data">
										<span class="d-block text-center">{{ data.value }}</span>
									</template>				
									<template #empty="scope">
										<template v-if="getsearch">
											<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
										</template>
										<template v-else>
											<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
										</template>
								    </template>
								</b-table>
							</b-card>
			            </b-tab>                  
			        </b-tabs>
			        <div v-if="this.total > 0" class="paging-cus relative">
						<b-pagination
					      	v-model="currentPage"
					      	:total-rows="total"
					      	:per-page="perPage"
					      	aria-controls="custom-table"
					      	first-number
				        	last-number
				        	class="just-center"
					    ></b-pagination>
					    <div class="limit-per">
							<select v-model="selected" class="cursor-pointer">
							    <option v-for="limit in limitpage">{{ limit }}</option>
							</select>			  
					    </div>
					</div>    
			    </div>
			</b-col>
		</b-row>
	</section>
</template>

<script>

	import Breadcrumb from '../partial/Breadcrumb'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
	  		Breadcrumb,
	  		ToastificationContent
	  	},
	  	data() {
		    return {
				name:"Product",
		    	userData: JSON.parse(localStorage.getItem('userData')),
				perrmission:[],
		    	labels: { "itees-product": "" },
		    	searchQuery: null,
			    fields: [
                	{ key: 'id', label: 'ID.' },
			        { key: 'name', label: 'Product Name' },			        
			        { key: 'service', label: 'Services' },
			        { key: 'description', label: 'Description' },
			        { key: 'action', label: 'Actions' },
			    ],
		    	itemsData: [],	
		    	getsearch: null,
		    	timer: null,

		    	sort: {"field": "id","type": "asc"},
		    	perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
            }
		},	
		watch: {            
            getsearch(val) {
            	this.fetchList(val)
            },   
            currentPage(val){
				this.fetchList(this.getsearch)
			},
			selected(val){
			if(val) {
				this.perPage = val
				this.fetchList(this.getsearch)
			}
			}
        },	
        mounted () {
		    this.selected = '10'
		},
		created(){
			this.checkPermission()
			this.fetchList(this.getsearch)
		},
		methods: {
			checkPermission(){
				var action = [];
				var namePage = this.name;
				this.userData?.permission.map(function (value) {
					if(value.module === namePage){
						action = value.actions
					}
					
				})
				this.permission = action 
			},
			fetchList(valueSearch){
				this.$store
				.dispatch('project/listProducts', {
					search: valueSearch,
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort
				})
				.then(response => {
					this.itemsData = response.data.data.data
					this.total = response.data.data.total
					// console.log('listProducts: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			single_product(item){
				this.$router.push(`/admin/itees-product/${item.id}`)
			},
			addProduct(){
				this.$router.push(`/admin/itees-product/add-product`)	
			},
			searchValue(val) {
				if (this.timer) {
			        clearTimeout(this.timer);
			        this.timer = null;		        
			    }
			    this.getsearch = val
			},
			headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchList(this.getsearch)
			},
			showDeleteAction(id, name){
				this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          	title: 'Delete',
		          	size: 'sm',
		          	buttonSize: 'sm',
		          	okVariant: 'danger',
		          	okTitle: 'Delete',
		          	cancelTitle: 'Cancel',
					cancelVariant: 'outline-danger',
		          	footerClass: 'p-2 modal-delete',
		          	hideHeaderClose: false,
		          	centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.deleteItem(id, name)  
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			deleteItem(id, title){
				this.$store
				.dispatch('project/deleteProduct', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.fetchList(this.getsearch)	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			},
		}
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";
	
	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
	.products-table table thead tr th:first-child{
		width: 100px
	}
	.products-table table thead tr th:nth-of-type(2){
		width: 200px
	}
	.products-table table thead tr th:last-child{
		width: 150px
	}
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
</style>